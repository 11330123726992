import React from "react";
import Avatar from "./avatar";
import RecordingControls from "./recording-controls";
import camWhite from "img/video-camera.png";
import { User } from "../hooks";

export default ({
  user,
  roomData,
  clientId,
  hasLocalVideo,
  mediaDevice,
  endCall,
  enterRoom,
}: {
  user?: User;
  roomData?: any;
  clientId?: string;
  hasLocalVideo?: boolean;
  mediaDevice?: any;
  endCall?: any;
  enterRoom?: any;
}) => {
  return (
    <div className="bottomBar">
      <div className="title">
        <div style={{ display: "flex" }}>
          <Avatar
            avatar={user?.info?.avatar}
            online={
              !!roomData?.participants?.find?.(
                (p: { id: string }) => p.id !== clientId
              )
            }
          />
          <div className="username" style={{ margin: 12 }}>
            <p style={{ margin: 0 }}>{user?.name}</p>
          </div>
        </div>
        <RecordingControls
          mediaDevice={mediaDevice}
          onEndRecording={() => endCall?.(false)}
        />
      </div>
      <button
        disabled={!hasLocalVideo}
        className="enter-room-btn box"
        style={{
          color: "#fff",
          border: 0,
          borderRadius: 16,
          padding: 18,
          background: hasLocalVideo ? "#21FF9F" : "#cecece",
          fontSize: 18,
          lineHeight: "1em",
          margin: 12,
        }}
        onClick={() => {
          enterRoom?.();
        }}
      >
        <img
          src={camWhite}
          alt="camera icon"
          style={{ width: 24, margin: "0 12px" }}
        />
        Entrar na sala
      </button>
    </div>
  );
};
