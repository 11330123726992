import React from "react";

export default (props: { avatar?: string; name?: string }) => {
  return (
    <div className="avatar clinic">
      <img
        src={props.avatar}
        alt={props.name}
        style={{
          height: 48,
          flex: 0,
          margin: 12,
          lineHeight: "48px",
          color: "#fff",
          fontSize: 22,
        }}
      />
      <div
        style={{
          width: 12,
          height: 12,
          position: "absolute",
          bottom: 0,
          right: 0,
        }}
      />
    </div>
  );
};
