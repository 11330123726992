import React from "react";
import Avatar from "./avatar";
import RecordingControls from "./recording-controls";
import camWhite from "img/video-camera.png";
import { User } from "../hooks";
import Loader from "./loader";

export default ({
  user,
  roomData,
  clientId,
  hasLocalVideo,
  mediaDevice,
  endCall,
  enterRoom,
}: {
  user?: User;
  roomData?: any;
  clientId?: string;
  hasLocalVideo?: boolean;
  mediaDevice?: any;
  endCall?: any;
  enterRoom?: any;
}) => {
  return (
    <div className="peer-placeholder">
      <div className="card">
        <Loader status={"Aguardando entrada..."} />
        <div className="title">
          <div style={{ display: "flex" }}>
            <Avatar
              avatar={user?.info?.avatar}
              online={
                !!roomData?.participants?.find?.(
                  (p: { id: string }) => p.id !== clientId
                )
              }
            />
            <div className="username" style={{ margin: 12 }}>
              <h4 style={{ margin: 0 }}>{user?.name ?? "Integrante"}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
