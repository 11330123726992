import React from "react";

export default ({ onClick }: { onClick?: () => void }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      flexDirection: "column",
    }}
  >
    <h2
      style={{
        color: "rgba(255,255,255,0.85)",
        fontSize: 16,
        margin: "0 24px",
      }}
    >
      É necessário aceitar o termo antes de entrar na sala:
    </h2>
    <div
      style={{
        color: "rgba(255,255,255,0.85)",
        fontSize: 16,
        overflow: "scroll",
        maxHeight: 400,
        margin: 24,
        marginTop: 0,
      }}
    >
      <h4>
        TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA O USO DA TELEMEDICINA
        (LEI Nº 13.989, DE 15 DE ABRIL DE 2020)
      </h4>
      <p>
        Estou ciente que a telemedicina apresenta limitações por não
        possibilitar o meu exame médico presencial, podendo ser, em alguns
        casos, limitados por vídeo e/ou foto
      </p>
      Declaro estar ciente que a telemedicina é uma alternativa nesse momento de
      exceção, e caso eu ou o meu médico percebamos a necessidade da avaliação
      presencial ou em caso de interrupção da comunicação por{" "}
      <p>
        falha técnica, a telemedicina é considerada interrompida e o paciente
        encaminhado ao ambulatório ou hospital, dentro da necessidade.
      </p>
      <p>
        Declaro que estou ciente de que as informações por mim prestadas, via
        online, poderão ser gravadas e armazenadas pelo médico acima mencionado,
        o qual guardará o devido sigilo, conforme exigido por lei.
      </p>
      <p>
        Após esse período de excepcionalidade, sei que o médico estará a
        disposição presencial para o tratamento.
      </p>
      <p>
        Nos casos de teleconsulta, eu autorizo o envio de
        dados/documentos/fotos/vídeos que permitam a minha identificação, uma
        vez que ela se faz entre médicos.
      </p>
      <p>
        Este consentimento para uso de imagens se dá de forma gratuita, sem
        qualquer custo em meu benefício ou prejuízo à minha pessoa, e poderá ser
        revogado a meu pedido ou solicitação.
      </p>
      Estou ciente que, uma vez consentida a telemedicina, em qualquer de sua
      modalidade, o médico deverá elaborar um prontuário para cada paciente,
      contendo os dados clínicos necessários para a boa condução do{" "}
      <p>
        caso, sendo preenchido em ordem cronológica com data, hora, assinatura e
        número de registro do médico no Conselho Regional de Medicina.
      </p>
      Declaro também que fui informado que a emissão de receitas, relatórios e
      atestados médicos à distância é válida em meio eletrônico, durante a
      realização da telemedicina, segundo a Portaria MS n° 467, de{" "}
      <p>20 de março de 2020i ii iii.</p>
      Os serviços prestados nas modalidades de telemedicina serão remunerados
      conforme acordo prévio entre o médico e seu paciente, pessoa física ou
      jurídica. Fica a critério do médico a definição e cobrança de seus
      honorários bem como o período de retorno, referente ao ato de
      telemedicina, cujos valores devem ser acordados previamente à consulta. Ao
      assinar esse termo declaro que aceito o acordo previamente{" "}
      <p>firmado entre o eu e meu médico.</p>
      <p>
        Caso opte pelo não pagamento da consulta, sei que devo me dirigir ao
        ambulatório ou hospital.
      </p>
      Reconheço a excepcionalidade do momento, e me comprometo a preservar e
      manter a confidencialidade das imagens (foto e vídeo), dos dados, dos
      diálogos, orientações, prescrições e todo o conteúdo referentes à forma da
      telemedicina a que fui submetido, sob pena de sansões legais por exposição
      de dados e imagem. Da mesma forma, afirmo meu compromisso em não gravar,
      fotografar ou editar qualquer momento ou{" "}
      <p>
        etapa da telemedicina empregada, assim como asseguro minha ciência que
        tal fato não tem o consentimento do meu médico.
      </p>
      Toda pessoa tem o seu direito de imagem, por esta razão a publicação de
      qualquer material sem autorização prévia da outro trata-se de crime, de
      acordo com o art. 5º da Constituição Federal "são{" "}
      <p>
        invioláveis a intimidade, a vida privada, a honra e a imagem das
        pessoas, assegurando o direito a indenização pelo dano material ou moral
        decorrente de sua violação”.
      </p>
      Assim, ao assinar esse termo declaro estar seguro (a) e ciente para a
      realização da telemedicina e ao mesmo tempo, declaro e dou fé que a
      assinatura abaixo é minha devido a impossibilidade de{" "}
      <p>autenticação, pelo momento de pandemia.</p>
      Plenamente ciente e esclarecido, DECLARO estar totalmente informado de
      todos os fatores de risco acima mencionados, dando meu aceite para que os
      procedimentos e tratamentos propostos pelo meu médico{" "}
      <p>
        assistente sejam levados a termo, na forma por ele indicada, no intuito
        do restabelecimento de minha saúde.
      </p>
    </div>
    <button className="btn" onClick={onClick}>
      Aceitar e continuar
    </button>
  </div>
);
