import React from "react";
import logo from "img/logo.png";
import BottomBar from "./bottom-bar";
import LocalVideoScreen from "./VideoCam";
import Loader from "./loader";
import Terms from "./terms";
import Placeholder from "./video-placeholder";
import socket from "../lib/socket";
import PeerConnection from "../lib/PeerConnection";
import { useLoggedIn, useRoom, useClinic, APIPATH } from "../hooks";
import AvatarClinic from "./avatar-clinic";

const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
function getParams(url: string) {
  var params: any = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

const App = () => {
  const params = getParams(window.location.href);
  const [user, loading, error] = useLoggedIn(params.token);
  const [room, rloading, rerror] = useRoom(params.token, params.room);
  const isLoading = loading || rloading;

  const clinic = useClinic(params.token, params.clinic);

  const [inCall, setInCall] = React.useState(false);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [hasLocalVideo, setHasLocalVideo] = React.useState(false);
  const [localSrc, setLocalSrc] = React.useState(null as any);
  const [peerSrc, setPeerSrc] = React.useState(null as any);
  const [clientId, setClientId] = React.useState("");
  const [pc, setPC] = React.useState({} as any);
  const [callFrom, setCallFrom] = React.useState("");
  const [roomData, setRoomData] = React.useState({ participants: [] } as {
    participants: { id: string; is_host: boolean }[];
  });

  const setupDevices = (config: { video: any; audio: any }) => {
    const pc = new PeerConnection()
      .on("localStream", (src: any) => {
        setLocalSrc(src);
        setHasLocalVideo(true);
      })
      .on("peerStream", (src: any) => {
        setPeerSrc(src);
      })
      .startLocalDevices(config);
    setPC(pc);
  };

  const enterRoom = (data: any) => {
    const params = getParams(window.location.href);
    const authtoken = localStorage.getItem(params.token);
    socket.emit("room.join", {
      token: authtoken,
      room_id: params.room,
    });
  };

  const startCall = (isCaller: boolean, friendID: string) => {
    console.log("startCall called");
    if (!hasLocalVideo) {
      return;
    }
    pc.start(friendID, isCaller);
  };

  const endCall = (isStarter: boolean) => {
    setHasLocalVideo(false);
    setInCall(false);
    setLocalSrc(null);
    setPeerSrc(null);
    pc.stop?.(isStarter);
    setPC({});
  };
  const memoizedEndCall = React.useCallback(endCall, [
    setHasLocalVideo,
    setInCall,
    setLocalSrc,
    setPeerSrc,
    pc,
    setPC,
  ]);
  const memoizedStartCall = React.useCallback(startCall, [hasLocalVideo, pc]);

  React.useEffect(() => {
    socket.emit("init", { clientId: "" });
  }, []);

  React.useEffect(() => {
    console.log("rendering again??");
    socket
      .on("init", (d: { id: string }) => {
        document.title = `${d.id} - VideoCall`;
        setClientId(d.id);
      })
      .on("request", (d: { from: string }) => {
        console.log("request", d);
        memoizedStartCall(false, d.from);
        setCallFrom(d.from);
      })
      .on("call", (data: { candidate?: any; sdp?: any }) => {
        console.log("called me", data);
        if (data.sdp) {
          pc.setRemoteDescription(data.sdp);
          if (data.sdp.type === "offer") pc.createAnswer();
        } else pc.addIceCandidate(data.candidate);
      })
      .on(
        "room.updated",
        (data: { participants: { id: string; is_host: boolean }[] }) => {
          setRoomData(data);
          if (!hasLocalVideo) {
            return;
          }
          const partner = data.participants.find((p) => p.id !== clientId);
          const me = data.participants.find((p) => p.id === clientId);
          if (partner && me && me.is_host) {
            console.log("--> got ir", partner.id, memoizedStartCall);
            memoizedStartCall(true, partner.id);
          }
          console.log("entered room", partner, me);
          setInCall(true);
        }
      )
      .on("end", () => memoizedEndCall(false));
  }, [pc, clientId, hasLocalVideo, memoizedEndCall, memoizedStartCall]);
  console.log(user, room?.participants);

  const isPatient = user?.applID === "patient";

  const topLogo = clinic?.avatar ? `${APIPATH}/files/${clinic?.avatar}` : logo;
  console.log(clinic, topLogo);

  return (
    <div className={`App ${inCall ? "in-call" : ""} ${isiOS ? "ios" : ""}`}>
      <header>
        <AvatarClinic avatar={topLogo} name={clinic?.name} />
      </header>

      {isLoading ? (
        <Loader
          status={
            (loading && "Carregando usuário") ||
            (rloading && "Carregando sessão de vídeo") ||
            error ||
            rerror
          }
        />
      ) : isPatient && !acceptedTerms ? (
        <Terms
          onClick={() => {
            setAcceptedTerms(true);
          }}
        />
      ) : (
        <>
          <LocalVideoScreen
            localSrc={localSrc}
            peerSrc={peerSrc}
            mediaDevice={pc.mediaDevice}
            enableMedia={() => setupDevices({ audio: true, video: true })}
          >
            {inCall ? (
              <Placeholder
                user={room?.participants?.find?.(
                  (p) => p.userID !== user?.userID
                )}
              />
            ) : null}
          </LocalVideoScreen>
          <BottomBar
            roomData={roomData}
            hasLocalVideo={hasLocalVideo}
            enterRoom={enterRoom}
            endCall={endCall}
            clientId={clientId}
            mediaDevice={pc.mediaDevice}
            user={room?.participants?.find?.((p) => p.userID !== user?.userID)}
          />
        </>
      )}
      {clinic && (
        <footer>
          <img src={logo} alt="Udok" className="scrollto" />
        </footer>
      )}
    </div>
  );
};

export default App;
