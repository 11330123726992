import React from "react";
import userprofile from "img/user-profile.png";

export default (props: { online: boolean; avatar?: string }) => {
  return (
    <div
      className="avatar"
      style={{ position: "relative", height: 64, width: 64 }}
    >
      <img
        src={props.avatar ?? userprofile}
        alt="avatar"
        style={{ height: 64, width: 64 }}
      />
    </div>
  );
};
