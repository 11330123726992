import React from "react";
import mic from "img/microphone.png";
import micOn from "img/microphone-on.png";
import callEnd from "img/call-end.png";
import camDark from "img/video-camera-dark.png";
import camDarkOn from "img/video-camera-dark-on.png";

const RecordingControls = ({ onEndRecording, mediaDevice, config }: any) => {
  const [video, setVideo] = React.useState(config ? config.video : true);
  const [audio, setAudio] = React.useState(config ? config.audio : true);

  React.useEffect(() => {
    if (mediaDevice) {
      mediaDevice.toggle("Video", video);
      mediaDevice.toggle("Audio", audio);
    }
  });

  const toggleMediaDevice = (deviceType: "video" | "audio") => {
    if (deviceType === "video") {
      setVideo(!video);
      mediaDevice.toggle("Video");
    }
    if (deviceType === "audio") {
      setAudio(!audio);
      mediaDevice.toggle("Audio");
    }
  };

  return (
    <div className="recording-controls">
      <img
        src={audio ? micOn : mic}
        alt="toggle microphone"
        style={{
          width: 12,
          margin: 6,
        }}
        onClick={() => toggleMediaDevice("audio")}
      />
      <img
        src={video ? camDarkOn : camDark}
        alt="toggle video"
        style={{
          width: 24,
          margin: 6,
        }}
        onClick={() => toggleMediaDevice("video")}
      />
      <img
        src={callEnd}
        alt="end call"
        style={{
          width: 64,
          margin: 6,
          cursor: "pointer",
        }}
        onClick={onEndRecording}
      />
    </div>
  );
};

export default RecordingControls;
