var SOCKET_CONNECTING = 0;
var SOCKET_OPEN = 1;
var SOCKET_CLOSING = 2;
var SOCKET_CLOSED = 3;

class Sockit {
  __socket = null;
  __eventResolvers = {};
  __actionQueue = [];
  __retriesCount = 1;

  constructor() {
    setTimeout(this.__connect, 500);
  }

  __connect = () => {
    this.__socket = new WebSocket(
      "wss://call.udok.com.br:8085/session-manager"
    );
    this.__socket.onclose = (e) => {
      this.__retriesCount = this.__retriesCount + 1;
      console.log(
        "Socket is closed. Reconnect will be attempted in 1 second.",
        e.reason
      );
      setTimeout(() => {
        this.__connect();
      }, this.__retriesCount * 1000);
    };
    this.__socket.onopen = (e) => {
      this.__processQueue();
      this.__retriesCount = 0;
    };
    this.__socket.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      this.__socket.close();
    };
    this.__socket.onmessage = (e) => {
      console.log("get message", e.data);
      try {
        const action = JSON.parse(e.data);
        const func = this.__eventResolvers[action.type];
        func && func(action.payload);
      } catch (err) {
        console.error(err);
        console.warn("failed to parse", e.data);
      }
    };
  };

  __processQueue = () => {
    if (!this.__socket || this.__socket.readyState !== SOCKET_OPEN) {
      setTimeout(this.__processQueue, 501);
      return this;
    }
    while (this.__actionQueue.length > 0) {
      const action = this.__actionQueue.pop();
      this.__socket.send(JSON.stringify(action));
    }
  };

  on = (t, func) => {
    this.__eventResolvers[t] = func;
    return this;
  };

  emit = (t, payload) => {
    let action = {
      type: t,
      payload,
    };
    this.__actionQueue = [action, ...this.__actionQueue];
    this.__processQueue();
    return this;
  };
}
const s = new Sockit();
export default s;
