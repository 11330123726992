import React from "react";

const Loader = () => (
  <div className="lds-ripple">
    <div></div>
    <div></div>
  </div>
);

export default ({ status }: { status?: null | string }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      flexDirection: "column",
    }}
  >
    <Loader />
    {status && (
      <p style={{ color: "rgba(255,255,255,0.85)", fontSize: 16 }}>{status}</p>
    )}
  </div>
);
