import { useState, useEffect } from "react";
import axios from "axios";

export const APIPATH = "https://app.udok.com.br:8080";

export type User = {
  userID: string;
  email: string;
  createdAt: string;
  deletedAt: null | string;
  pushTokens: string[];
  info: {
    avatar: string;
    age: string;
  };
  applID: string;
  name: string;
};

export type Room = {
  roomID: string;
  participants: Array<User>;
  expiresAt: string;
  viseID: string;
  createAt: string;
  appoID: null | string;
};

export type Clinic = {
  clinID: string;
  name: string;
  description: string;
  banner: string;
  avatar: string;
  visible: string;
  info: null;
  createdAt: string;
};
export function useLoggedIn(token: string) {
  const authtoken = localStorage.getItem(token);
  const [user, setUser] = useState(null as null | User);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as null | string);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    if (authtoken) {
      const t = parseJwt(authtoken);
      getUser(authtoken, t.userID, source.token)
        .then((r) => {
          const item = r.data.data.item;
          setUser(buildUsr(item));
        })
        .finally(() => setLoading(false))
        .catch((e) => {
          console.error(e);
          setError((e.response?.data?.error || e).message);
        });
    } else {
      axios
        .post(
          `${APIPATH}/auth/one-time-login`,
          {
            token,
          },
          {
            cancelToken: source.token,
          }
        )
        .then((r) => {
          const item = r.data.data.item;
          setUser(item.user);
          localStorage.setItem(token, item.jwt);
        })
        .finally(() => setLoading(false))
        .catch((e) => {
          console.error(e);
          setError((e.response?.data?.error || e).message);
        });
    }
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [token, authtoken]);

  return [user, loading, error] as [null | User, boolean, null | string];
}

export function useRoom(token: string, roomID: string) {
  const authtoken = localStorage.getItem(token) || "";
  const [room, setRoom] = useState(null as null | Room);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as null | string);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    axios
      .get(`${APIPATH}/api/video-session/${roomID}`, {
        headers: {
          Authorization: "Bearer " + authtoken,
          cancelToken: source.token,
        },
      })
      .then((r) => {
        const rm = r.data.data.item;
        return Promise.all(
          rm.participants.map((userID: string) => {
            return getUser(authtoken, userID, source.token);
          })
        ).then((r) => {
          setRoom({
            ...rm,
            participants: r.map((res: any) => {
              const item = res.data.data.item;
              return buildUsr(item);
            }),
          });
        });
      })
      .finally(() => setLoading(false))
      .catch((e) => {
        console.error(e);
        setError((e.response?.data?.error || e).message);
      });
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [authtoken, roomID]);

  return [room, loading, error] as [null | Room, boolean, null | string];
}

export function useClinic(token: string, clinID: string) {
  const authtoken = localStorage.getItem(token) || "";
  const [clinic, setClinic] = useState(null as null | Clinic);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    getClinic(authtoken, clinID, source.token)
      .then((r) => {
        const item = r.data.data.item;
        setClinic(item);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [token, clinID]);

  return clinic as Clinic;
}

function getUser(authtoken: string, userID: string, source?: any) {
  return axios.get(`${APIPATH}/api/users/${userID}`, {
    headers: { Authorization: "Bearer " + authtoken },
    cancelToken: source.token,
  });
}

function getClinic(authtoken: string, clinID: string, source?: any) {
  return axios.get(`${APIPATH}/api/clinics/${clinID}`, {
    headers: { Authorization: "Bearer " + authtoken },
    cancelToken: source.token,
  });
}

function parseJwt(token: string) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function buildUsr(u: any): User {
  const usr = {
    ...u,
    name: u?.patient?.name ?? u?.doctor?.name,
    info: {
      ...(u?.info ?? {}),
      avatar: u?.info?.avatar
        ? `${APIPATH}/files/${u?.info?.avatar}`
        : undefined,
    },
  };
  return usr as User;
}
