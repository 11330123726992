import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

export class AppStreamCam extends React.Component {
  state = { error: null, hasPermissions: false };
  streamCamVideo = () => {
    var constraints = { audio: true, video: { width: 320 } };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((mediaStream) => {
        var video = document.querySelector("video.local-stream");

        video.srcObject = mediaStream;
        video.onloadedmetadata = (e) => {
          video.play();
          this.setState({ error: null, hasPermissions: true });
          this.props.onVideoLoaded && this.props.onVideoLoaded();
        };
      })
      .catch((err) => {
        console.log("has error", err);
        this.setState({
          error: err.name + ": " + err.message,
        });
      });
  };
  componentDidCatch(error, info) {
    this.setState({ error: JSON.stringify({ error, info }) });
  }
  render() {
    console.log(this.state.hasPermissions);
    return (
      <div id="container">
        {!this.state.hasPermissions ? (
          <div style={{ padding: 12 }}>
            <h4 style={{ color: "#fff", marginTop: 0 }}>
              Você precisa permitir o acesso à câmera e ao microfone
            </h4>
            <button
              onClick={this.streamCamVideo}
              className="blurred"
              style={{
                color: "#fff",
                border: 0,
                borderRadius: 3,
                padding: "8px 48px",
                background: "#14E2FF",
              }}
            >
              Permitir
            </button>
          </div>
        ) : null}
        <video
          playsInline
          className="remote-stream"
          src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
        />
        <video autoPlay playsInline className="local-stream" muted />
      </div>
    );
  }
}

function CallWindow({
  peerSrc,
  localSrc,
  config,
  mediaDevice,
  enableMedia,
  children,
}) {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const [muted, setMuted] = useState(true);
  const [hasSrc, setHasSrc] = useState(false);

  useEffect(() => {
    if (peerVideo.current && peerSrc) {
      setHasSrc(true);
      peerVideo.current.srcObject = peerSrc;
    } else {
      setHasSrc(false);
    }
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
    setMuted(false);
  }, [localSrc, peerSrc, setHasSrc]);

  return (
    <div id="container">
      {!localSrc ? (
        <div style={{ padding: 12 }}>
          <h4 style={{ color: "#fff", marginTop: 0 }}>
            Você precisa permitir o acesso à câmera e ao microfone
          </h4>
          <button
            onClick={enableMedia}
            className="blurred"
            style={{
              color: "#fff",
              border: 0,
              borderRadius: 3,
              padding: "8px 48px",
              background: "#14E2FF",
            }}
          >
            Permitir
          </button>
        </div>
      ) : null}
      <video
        id="peerVideo"
        ref={peerVideo}
        autoPlay
        playsInline
        muted={muted}
        className="remote-stream"
      />
      <video
        id="localVideo"
        className="local-stream"
        ref={localVideo}
        autoPlay
        playsInline
        muted
      />
      {!peerSrc && localSrc ? children : null}
    </div>
  );
}

CallWindow.propTypes = {
  localSrc: PropTypes.object, 
  peerSrc: PropTypes.object, 
  config: PropTypes.shape({
    audio: PropTypes.bool.isRequired,
    video: PropTypes.bool.isRequired,
  }),
  mediaDevice: PropTypes.object,
  enableMedia: PropTypes.func.isRequired,
};

export default CallWindow;
